import React from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { HeartIcon } from "lucide-react";




import axios from 'axios';
import { Loader2 } from "lucide-react";
import { serverbaseURL } from "../constant/index.jsx";
import { AuthContext } from "../provider/AuthProvider";
import { motion } from 'framer-motion';
import LoginModal from '../components/LoginModal';
import {
    Wallet,
    VideoIcon,
    Paintbrush,
    Mic,
    X,
    Sparkles,
    ChevronLeft,
    ChevronRight
} from "lucide-react";
import MyGenerations from "./MyGenerations.jsx";
import { toast } from "react-toastify";
import DataInputForm from '../components/DataInputForm';
import { div } from 'framer-motion/client';


export const TemplateDetails = () => {
    const [templates, setTemplates] = useState([]); // This will store marketplace templates
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [showGeneratedVideo, setShowGeneratedVideo] = useState(false);
    const [generatedVideoUrl, setGeneratedVideoUrl] = useState(null);
    const [GenerationCount, setGenerationCount] = useState(0);

    const [generationError, setGenerationError] = useState(null);
    // const [activeTab, setActiveTab] = useState("/templates");
    const { user, userCredits, updateCredits } = useContext(AuthContext);
    const userEmail = user?.email;
    const userUID = user?.uid;


    const [showPreGenModal, setShowPreGenModal] = useState(false);
    const [selectedNarrator, setSelectedNarrator] = useState("pNInz6obpgDQGcFmaJgB");
    const [selectedSeller, setSelectedSeller] = useState(null);
    const [showMyGenerations, setShowMyGenerations] = useState(false);
    const [marketplaceTemplates, setMarketplaceTemplates] = useState([]);
    const [showGeneratingModal, setShowGeneratingModal] = useState(false);
    const [templateScenes, setTemplateScenes] = useState([]);
    const [loadingScenes, setLoadingScenes] = useState(false);
    const [editableScenes, setEditableScenes] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [templateVideo, setTemplateVideo] = useState([]);

    // Add loading state
    const [isLoading, setIsLoading] = useState(true);
    const [uploadedVideo, setUploadedVideo] = useState(null);
    const [generatedClips, setGeneratedClips] = useState([]);
    const [currentClipIndex, setCurrentClipIndex] = useState(0);
    const [showClipsModal, setShowClipsModal] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);


    // Fetch initial count when component mounts
    useEffect(() => {
        const fetchCount = async () => {
            try {
                const response = await axios.get(`${serverbaseURL}api/template-generation-count/${selectedTemplate.id}`);
                setGenerationCount(response.data.count);
            } catch (error) {
                console.error('Error fetching generation count:', error);
            }
        };
        fetchCount();
    }, [selectedTemplate?.id]);



    const fetchTemplateScenes = async () => {
        if (!selectedTemplate?.id) return; // Add the check here
        console.log(selectedTemplate?.id)
        setLoadingScenes(true);
        try {
            const response = await axios.get(`${serverbaseURL}api/template-scenes/${selectedTemplate.id}`);
            if (response.data.success) {
                setTemplateScenes(response.data);
                if (response.data.type === "scenes") {
                    setEditableScenes(response.data.scenes);
                } else if (response.data.type === "text") {
                    setEditableScenes([{ prompt: response.data.prompt }]);
                }
            } else {
                toast.error("Failed to load template content");
            }
        } catch (error) {
            console.error("Error fetching template content:", error);
            toast.error("Failed to load template content");
            setTemplateScenes({ type: "none" });
            setEditableScenes([]);
        } finally {
            setLoadingScenes(false);
        }
    };


    // Automatically fetch data when navigating to the details page
    useEffect(() => {
        fetchTemplateScenes();
    }, [selectedTemplate?.id]); // Re-run when template ID changes



    useEffect(() => {
        const fetchUserCredits = async () => {
            if (user?.uid) {
                try {
                    const response = await axios.post(`${serverbaseURL}get_user_details`, {
                        uid: user.uid
                    });
                    updateCredits(response.data.credits);
                } catch (error) {
                    console.error("Failed to fetch user credits:", error);
                }
            }
        };

        fetchUserCredits();
    }, [user]);



    const fetchTemplateVideos = async (templateId) => {
        console.log("Fetching videos for template ID:", templateId);
        try {
            const response = await axios.get(`${serverbaseURL}get_template_videos/${templateId}`);
            console.log("API Response:", response.data);

            if (response.data.success) {
                const videoData = response.data.data;

                // Ensure it's an array before setting state
                if (Array.isArray(videoData)) {
                    setTemplateVideo(videoData);
                } else if (videoData && typeof videoData === "object") {
                    setTemplateVideo([videoData]); // Convert single object to array
                } else {
                    setTemplateVideo([]); // Handle unexpected cases
                }

                console.log("Stored in State:", videoData);
            } else {
                toast.error("Failed to fetch template video examples");
            }
        } catch (error) {
            console.error("Error fetching template videos:", error);
            toast.error("Failed to load template examples");
        }
    };

    useEffect(() => {
        console.log("Updated templateVideo state:", templateVideo);
    }, [templateVideo]);

    // Add this useEffect to trigger the fetch when template changes
    useEffect(() => {
        if (selectedTemplate?.id) {
            fetchTemplateVideos(selectedTemplate.id);
        }
    }, [selectedTemplate?.id]);




    const { state } = useLocation();
    const navigate = useNavigate();

    // Redirect if no template data
    useEffect(() => {
        if (!state?.template) {
            navigate("/templates");
        } else {
            setSelectedTemplate(state.template);
        }
    }, [state, navigate]);

    if (!selectedTemplate) return null; // Ensure UI doesn't break before state updates

    console.log("Selected Template:", selectedTemplate);



    const handleFinalGenerate = async () => {
        setIsGenerating(true);
        toast.success(`Video Generation added to render queue successfully!`);
        setGenerationError(null);

        try {
            console.log('Selected template:', selectedTemplate);
            console.log('Selected template flowData:', selectedTemplate.flowData);
            const originalCredits = userCredits;
            const newCredits = userCredits - selectedTemplate.credits;
            updateCredits(newCredits);
            const deductionResponse = await axios.post(`${serverbaseURL}deduct_credits`, {
                uid: user.uid,
                credits: selectedTemplate.credits
            });
            await axios.post(`${serverbaseURL}add-generation-status`, {
                uid: user.uid,
                templateId: selectedTemplate.id,
                templateName: selectedTemplate.title,
            });
            const template = selectedTemplate;
            if (template.id >= 1 && template.id <= 15) {

                const isSpecialTemplate = [4, 6, 9, 10, 12].includes(template.id);

                if (isSpecialTemplate && !template.narratorId) {
                    throw new Error('Narrator ID is required for this template');
                }
                const endpoint = isSpecialTemplate
                    ? `${serverbaseURL}create_template_video_cloudinary_two`
                    : `https://amc-flow.onrender.com/api/generate-from-template`;

                const payload = isSpecialTemplate
                    ? {
                        id: template.id,
                        themeTemplate: template.themeTemplate,
                        topic: template.title,
                        email: userEmail,
                        uid: userUID,
                        height: template.height,
                        width: template.width,
                        music: template.music || "None",
                        musicDuration: template.musicDuration || 0,
                        storyPrompt: template.storyPrompt,
                        style: template.style,
                        styleUUID: template.styleUUID,
                        narratorId: template.narratorId,
                        credits: template.credits
                    }
                    : {
                        templateId: template.id.toString(),
                        topic: template.title,
                        email: userEmail,
                        uid: userUID,
                        id: template.id,
                        credits: template.credits
                    };
                const response = await axios.post(endpoint, payload);
                console.log(response);
                await axios.post(`${serverbaseURL}update-generation-status`, {
                    uid: user.uid,
                    templateId: selectedTemplate.id,
                });
                if (!response.data || !response.data.videoUrl) {
                    throw new Error(response.data?.error || 'No video URL received from server');
                }
                setGeneratedVideoUrl(response.data.videoUrl);
                setShowGeneratedVideo(true);
            }

            // Use edited scenes if available
            const scenesToUse = isEditing ? editableScenes :
                (templateScenes.type === 'scenes' ? templateScenes.scenes :
                    [{ prompt: templateScenes.prompt }]);
            // Create a modified template
            const modifiedTemplate = {
                ...template,
                flowData: {
                    ...template.flowData,
                    data: {
                        ...template.flowData?.data,
                        nodes: template.flowData?.data.nodes.map(node => {
                            if (node.data?.type === 'prompt-input') {
                                const updatedScenes = editableScenes.map((scene, index) => {
                                    const originalScene = node.data.properties.scenes[index];
                                    return {
                                        id: originalScene?.id || index + 1,
                                        prompt: scene.prompt
                                    };
                                });

                                return {
                                    ...node,
                                    data: {
                                        ...node.data,
                                        properties: {
                                            ...node.data.properties,
                                            scenes: updatedScenes
                                        }
                                    }
                                };
                            } else if (node.data?.type === 'text-generation') {
                                return {
                                    ...node,
                                    data: {
                                        ...node.data,
                                        properties: {
                                            ...node.data.properties,
                                            prompt: editableScenes[0]?.prompt || ''
                                        }
                                    }
                                };
                            }
                            return node;
                        })
                    }
                }
            };
            const payload = {
                templateId: modifiedTemplate.id.toString(),
                topic: modifiedTemplate.title,
                email: userEmail,
                uid: userUID,
                id: modifiedTemplate.id,
                credits: modifiedTemplate.credits,
                flowData: modifiedTemplate.flowData
            };

            console.log("Request Payload:", payload); // This logs the data being sent to the server

            const response = await axios.post(`${serverbaseURL}api/generate-from-template-user`, payload);

            console.log(response);
            await axios.post(`${serverbaseURL}update-generation-status`, {
                uid: user.uid,
                templateId: selectedTemplate.id,
            });
            if (!response.data || !response.data.videoUrl) {
                throw new Error(response.data?.error || 'No video URL received from server');
            }
            setGeneratedVideoUrl(response.data.videoUrl);
            setShowGeneratedVideo(true);
        } catch (error) {
            console.error('Operation failed:', error);
            await axios.post(`${serverbaseURL}update-generation-status`, {
                uid: user.uid,
                templateId: selectedTemplate.id,
            });
            let errorMessage = 'Failed to generate video. Please try again.';
            const originalCredits = userCredits + selectedTemplate.credits;
            if (error.response?.status === 400 && error.response?.data?.message === "Not enough credits.") {
                errorMessage = "You don't have enough credits for this operation.";
                updateCredits(originalCredits);
            } else if (error.response?.status === 404) {
                errorMessage = "User account not found. Please try logging in again.";
            } else if (error.message.includes('Narrator ID')) {
                errorMessage = 'Please select a narrator voice before generating the video.';
            } else if (error.message.includes('audio')) {
                errorMessage = 'Failed to generate audio. Please try a different narrator voice.';
            }
            setGenerationError(errorMessage);
            if (error.response?.status !== 400) {
                try {
                    const userDetailsResponse = await axios.post(`${serverbaseURL}get_user_details`, {
                        uid: user.uid
                    });
                    if (userDetailsResponse.data.credits < originalCredits) {
                        await axios.post(`${serverbaseURL}refund_credits`, {
                            uid: user.uid,
                            credits: selectedTemplate.credits
                        });
                        const finalCreditsResponse = await axios.post(`${serverbaseURL}get_user_details`, {
                            uid: user.uid
                        });
                        updateCredits(finalCreditsResponse.data.credits);
                    } else {
                        updateCredits(originalCredits);
                    }
                } catch (refundError) {
                    console.error('Failed to handle credits:', refundError);
                    updateCredits(originalCredits);
                }
            }
        } finally {
            setIsGenerating(false);
        }
    };


    // Add function to handle scene updates
    const handleSceneUpdate = (index, newPrompt) => {
        const updatedScenes = [...editableScenes];
        updatedScenes[index] = { ...updatedScenes[index], prompt: newPrompt };
        setEditableScenes(updatedScenes);
    };

    // Modify handleVideoUpload to not start processing immediately
    const handleVideoUpload = async (file) => {
        setUploadedVideo(file);
        toast.success('Video uploaded successfully! Click Generate to start processing.');
    };


    // Add new function to handle video processing
    const handleVideoProcessing = async () => {
        if (!uploadedVideo) {
            toast.error('Please upload a video first');
            return;
        }
        setIsProcessing(true);
        setIsGenerating(true);
        const formData = new FormData();
        formData.append('video', uploadedVideo);

        try {
            const response = await axios.post(`${serverbaseURL}process-video-to-shorts`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.data.success) {
                setGeneratedClips(response.data.clips);
                setShowClipsModal(true);
                setCurrentClipIndex(0);
                toast.success('Video processing completed successfully!');
            } else {
                toast.error('Failed to process video');
            }
        } catch (error) {
            console.error('Error processing video:', error);
            toast.error(error.response?.data?.message || 'Failed to process video');
        } finally {
            setIsProcessing(false);
            setShowGeneratingModal(false);
            setIsGenerating(false);
        }
    };


    // Add the handleLike function
    const handleLike = async (videoId) => {
        console.log("v", videoId)
        try {
            const response = await axios.put(` ${serverbaseURL}like_template_video/${videoId}`);
            if (response.data.success) {
                setTemplateVideo(prevVideos =>
                    prevVideos.map(video =>
                        video._id === videoId ? { ...video, likes: response.data.data.likes } : video
                    )
                );
            }
        } catch (error) {
            console.error('Error liking video:', error);
            // Optionally show error toast/message
        }
    };

    return (
        <>
            <div className="min-h-screen  px-6 py-8">
                <div className="max-w-[1600px] mx-auto">

                    {/* Main container */}
                    <div className=" ml-6 flex items-center gap-16  my-4 ">
                        {/* First div with heading */}
                        <div className="">
                            <h1 className="text-3xl font-bold text-gray-800">{selectedTemplate.title}</h1>
                        </div>

                        {/* Second div with image and name */}
                        <div className="flex items-center gap-2">
                            <img
                                src={selectedTemplate.seller.avatar}
                                alt={selectedTemplate.seller.name}
                                className="w-8 h-8 rounded-full object-cover "
                            />
                            <h2 className="text-md font-semibold text-gray-800">{selectedTemplate.seller.name}</h2>
                        </div>
                    </div>

                    <div className="flex flex-col lg:flex-row justify-evenly items-start">
                        {selectedTemplate && (
                            <div className="bg-gradient-to-br from-[#f8f8ff] via-[#e6e6fa] to-[#dcdcdc]  rounded-xl w-full max-w-lg  max-h-[90vh] flex flex-col overflow-hidden">
                                {/* Header */}
                                <div className="p-4 border-b">
                                    <div className="flex justify-between items-start">
                                        <h2 className="text-2xl font-bold text-gray-800">Generate Video</h2>

                                    </div>
                                </div>

                                {/* Scrollable Content */}
                                <div className="flex-1 overflow-y-auto p-4 space-y-4">
                                    {/* Template Image */}
                                    <div className="relative h-32 w-full rounded-lg overflow-hidden">
                                        <img
                                            src={selectedTemplate.image}
                                            alt={selectedTemplate.title}
                                            className="w-full h-full object-cover"
                                        />
                                        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                                    </div>

                                    {/* Template Details */}
                                    <div className="space-y-3">
                                        {/* Title Section */}
                                        <div className="bg-gray-50 p-3 rounded-lg">
                                            <div className="flex items-center gap-3 text-gray-700">
                                                <VideoIcon className="h-5 w-5 text-violet-600 shrink-0" />
                                                <div className="min-w-0">
                                                    <p className="text-sm text-gray-500">Title</p>
                                                    <p className="font-medium truncate">{selectedTemplate.title}</p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Video Upload Modal for Template 18 */}
                                        {selectedTemplate?.id === 18 ? (
                                            /* Video Upload Modal for Template 18 */
                                            <div className="flex justify-center items-center ">
                                                <div className="bg-white rounded-lg p-6 w-full max-w-md">
                                                    <div className="flex justify-between items-center mb-4">
                                                        <h2 className="text-2xl font-bold">Upload Video</h2>

                                                    </div>

                                                    <div className="space-y-4">
                                                        <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
                                                            <input
                                                                type="file"
                                                                accept="video/*"
                                                                onChange={(e) => {
                                                                    const file = e.target.files?.[0];
                                                                    if (file) {
                                                                        handleVideoUpload(file);
                                                                    }
                                                                }}
                                                                className="hidden"
                                                                id="video-upload"
                                                            />
                                                            <label
                                                                htmlFor="video-upload"
                                                                className="cursor-pointer text-gray-600"
                                                            >
                                                                <div>
                                                                    <span className="block mb-2">
                                                                        {uploadedVideo ? uploadedVideo.name : 'Drop your video here or click to upload'}
                                                                    </span>
                                                                    <span className="text-sm text-gray-500">MP4, MOV, or AVI</span>
                                                                </div>
                                                            </label>
                                                        </div>

                                                        {/* Add Generate button */}
                                                        <button
                                                            onClick={handleVideoProcessing}
                                                            disabled={!uploadedVideo || isProcessing}
                                                            className={`w-full py-3 px-4 rounded-lg shadow-lg transition-all duration-300 flex items-center justify-center gap-2 text-white font-semibold
                                                             ${!uploadedVideo || isProcessing
                                                                    ? 'bg-gray-400 cursor-not-allowed'
                                                                    : 'bg-gradient-to-r from-[#805af5] to-[#cd99ff] hover:shadow-xl hover:opacity-90'
                                                                }`}
                                                        >
                                                            {isProcessing ? (
                                                                <>
                                                                    <Loader2 className="animate-spin h-5 w-5" />
                                                                    <span>Processing...</span>
                                                                </>
                                                            ) : (
                                                                <span>Generate Clips</span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            /* Content Section for Other Templates */
                                            <div className="bg-gray-50 p-3 rounded-lg">
                                                <div className="flex justify-between items-center mb-2">
                                                    <h3 className="font-semibold text-gray-800">Content</h3>
                                                    <button
                                                        onClick={() => setIsEditing(!isEditing)}
                                                        className="text-sm text-violet-600 hover:text-violet-700 font-medium"
                                                    >
                                                        {isEditing ? 'Save Changes' : 'Edit Content'}
                                                    </button>
                                                </div>

                                                {loadingScenes ? (
                                                    <div className="text-center py-4">
                                                        <p className="text-gray-600">Loading...</p>
                                                    </div>
                                                ) : templateScenes.type === 'scenes' && editableScenes.length > 0 ? (
                                                    <div className="space-y-2 max-h-48 overflow-y-auto">
                                                        {editableScenes.map((scene, index) => (
                                                            <div
                                                                key={index}
                                                                className="bg-white p-3 rounded border border-gray-200"
                                                            >
                                                                <div className="flex justify-between items-start mb-2">
                                                                    <span className="font-medium text-sm text-gray-700">
                                                                        Scene {index + 1}
                                                                    </span>
                                                                </div>
                                                                {isEditing ? (
                                                                    <textarea
                                                                        value={scene.prompt}
                                                                        onChange={(e) => handleSceneUpdate(index, e.target.value)}
                                                                        className="w-full p-2 text-sm text-gray-600 border rounded focus:outline-none focus:ring-1 focus:ring-violet-500"
                                                                        rows="2"
                                                                    />
                                                                ) : (
                                                                    <p className="text-sm text-gray-600">
                                                                        {scene.prompt}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : templateScenes.type === 'text' && templateScenes.prompt ? (
                                                    <div className="bg-white p-3 rounded border border-gray-200">
                                                        <span className="font-medium text-sm text-gray-700">
                                                            ChatGPT Story Prompt
                                                        </span>
                                                        {isEditing ? (
                                                            <textarea
                                                                value={editableScenes[0]?.prompt || ''}
                                                                onChange={(e) => handleSceneUpdate(0, e.target.value)}
                                                                className="w-full mt-1 p-2 text-sm text-gray-600 border rounded focus:outline-none focus:ring-1 focus:ring-violet-500"
                                                                rows="3"
                                                            />
                                                        ) : (
                                                            <p className="text-sm text-gray-600 mt-1">
                                                                {templateScenes.prompt}
                                                            </p>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="text-center py-4">
                                                        <p className="text-gray-500">No content available for this template</p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {/* Credits Section */}
                                        <div className="bg-gray-50 p-3 rounded-lg">
                                            <div className="flex items-center gap-3 text-gray-700">
                                                <Wallet className="h-5 w-5 text-violet-600 shrink-0" />
                                                <div className="min-w-0">
                                                    <p className="text-sm text-gray-500">Credits Required</p>
                                                    <div className="flex items-center gap-2">
                                                        <p className="font-medium">{selectedTemplate.credits}</p>
                                                        <span className="text-sm text-gray-500">
                                                            (You have: {userCredits})
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Footer with Actions here*/}
                                <div className="p-4 border-t bg-gray-50">
                                    <button
                                        onClick={handleFinalGenerate}
                                        disabled={userCredits < selectedTemplate.credits}
                                        className={`w-full py-3 px-4 rounded-lg shadow-lg transition-all duration-300 flex items-center justify-center gap-2 text-white font-semibold
                  ${userCredits >= selectedTemplate.credits
                                                ? 'bg-gradient-to-r from-[#805af5] to-[#cd99ff] hover:shadow-xl hover:opacity-90'
                                                : 'bg-gray-400 cursor-not-allowed'}`}
                                    >
                                        {userCredits >= selectedTemplate.credits ? (
                                            <span>Generate Video</span>
                                        ) : (
                                            <span>Insufficient Credits</span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        )}
                        <div className="  rounded-xl max-w-4xl w-full min-h-screen overflow-y-auto px-4 pb-10 pt-4">
                            {/* Error Section */}
                            {generationError ? (
                                <div className=" flex justify-center items-center   ">
                                    <div className="bg-gradient-to-br from-[#f8f8ff] via-[#e6e6fa] to-[#dcdcdc]  mt-24 rounded-lg p-4 shadow-xl w-80">
                                        <h2 className="text-xl font-bold text-red-600 mb-3">Generation Failed</h2>
                                        <p className="text-gray-800 mb-3">{generationError}</p>

                                    </div>
                                </div>
                            ) : showGeneratedVideo && generatedVideoUrl ? (
                                /* Generated Video Section */
                                <div className=" align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                                    <div className="relative">
                                        {/* Video player */}
                                        <div className="aspect-video w-full">
                                            <video className="w-full h-full object-contain" controls autoPlay>
                                                <source src={generatedVideoUrl} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                    {/* Video information */}
                                    <div className="bg-white px-4 py-3">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Generated Video</h3>
                                        <p className="mt-1 text-sm text-gray-500">Your generated video is ready</p>
                                    </div>
                                </div>
                            ) : isGenerating ? (
                                /* Generating Section */
                                <div div className=' min-h-[300px] flex   justify-center items-center '>
                                    <div className=" flex justify-center items-center">
                                        <div className="bg-gradient-to-br from-[#f8f8ff] via-[#e6e6fa] to-[#dcdcdc]  mt-24 rounded-3xl p-8 shadow-2xl w-96 md:w-[400px] text-center  animate-slide-up flex flex-col items-center justify-center ">
                                            {/* Loader Animation */}
                                            <div className="flex flex-col items-center gap-4 animate-fade-in justify-center">
                                                <Loader2 className="animate-spin h-12 w-12 text-blue-600" />
                                                <h2 className="text-2xl font-semibold text-gray-900 animate-bounce">Generating Your Video</h2>
                                                <p className="text-gray-700 text-base animate-fade-in">This may take a few minutes...</p>
                                                <p className="text-sm text-gray-500 mt-3 max-w-xs leading-relaxed animate-fade-in">
                                                    You can  also check the progress in <span className="font-medium text-blue-600">"My Generations"</span>
                                                </p>

                                                {/* Additional Content */}
                                                <div className="mt-4 flex items-center gap-2 animate-fade-in justify-center">
                                                    <Sparkles className="h-6 w-6 text-yellow-500 animate-pulse" />
                                                    <p className="text-sm text-gray-600 italic">Enhancing your video with high-quality effects...</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                /* Preview Video Section */
                                <div className="inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-4xl sm:w-full">
                                    {/* Video player */}
                                    <div className="aspect-video w-full">
                                        <video className="w-full h-full object-contain" controls autoPlay>
                                            <source src={selectedTemplate.previewVideo} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    {/* Video information */}
                                    <div className="bg-white px-4 py-4">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                                            {selectedTemplate.name} - Preview
                                        </h3>
                                        <p className="mt-1 text-sm text-gray-500">
                                            Sample video preview of the template
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>


                    </div>
                </div>



                <div className="mt-8">

                    <h2 className="text-2xl font-bold text-gray-800 mb-6">
                        Generated Template by user: <span>{GenerationCount} Runs</span>
                    </h2>

                    {templateVideo.length === 0 ? (
                        <div className="text-center py-8 bg-gray-50 rounded-lg">
                            <p className="text-gray-500">No Generated Template by user</p>
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {templateVideo.map((video, index) => {
                                // Safely extract nested properties with optional chaining
                                const prompt = video?.flowData?.data?.nodes?.[0]?.data?.properties?.prompt
                                    || "Custom generated video";
                                console.log("prompt", prompt)
                                const username = video?.email || "Community Member";
                                const likes = video?.likes || 0;

                                return (
                                    <div
                                        key={video?.id || index}
                                        className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow"
                                    >
                                        {/* Video Container */}
                                        <div className="aspect-video bg-gray-100">
                                            {video.videoUrl ? (
                                                <div className="inline-block align-bottom bg-black  text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-4xl sm:w-full">

                                                    <div className="aspect-video w-full">
                                                        <video className="w-full h-full object-contain" controls >
                                                            <source src={video.videoUrl} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                </div>


                                            ) : (
                                                <div className="w-full h-full flex items-center justify-center text-gray-400">
                                                    <VideoIcon className="w-12 h-12" />
                                                </div>
                                            )}
                                        </div>

                                        {/* Card Footer */}
                                        <div className="p-4">
                                            <h3 className="font-medium text-gray-700 mb-2">
                                                Created by: {username}
                                            </h3>
                                            <p className="text-sm text-gray-600 ">
                                                {prompt}
                                            </p>
                                            <div className="flex items-center  mt-4 gap-2">
                                                <button
                                                    onClick={() => handleLike(video._id)}
                                                    className="flex items-center gap-1 text-gray-500 hover:text-red-500 transition-colors"
                                                >
                                                    <HeartIcon className="w-6 h-6 text-red " />
                                                    <span className="text-lg font-semibold text-red-500">{likes}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>


            </div >
        </>
    )
}
